import React, { Component } from "react";
import Web3 from "web3";
import detectEthereumProvider from '@metamask/detect-provider';
import BinaryContract from './contracts/Binary.json';
import { SocialIcon } from 'react-social-icons';
import DateCountdown from 'react-date-countdown-timer';
import "./App.css";

class App extends Component {
  state = { web3: null, accounts: null, contract: null, available: 5 };

componentDidMount = async () => {
  try {
      const provider = await detectEthereumProvider();
      const web3 = new Web3(provider);
      const accounts = await web3.eth.getAccounts();

      const networkId = await provider.request({method: 'net_version'});
      const deployedNetwork = BinaryContract.networks[networkId];
      const instance = new web3.eth.Contract(
        BinaryContract.abi,
        '1' && '0xa2E4c615A0d145a4912A9cdB7Fd5e10ca88D15cB'
        );
      this.setState({ web3, accounts, contract:instance}, this.initial);
    } catch (error) {
        alert('Failed to load web3 or contracts');
      }
  }

initial = async () => {
  var _available = await this.state.contract.methods.totalSupply().call();
  _available = 500 - _available;
  this.setState({available:_available});
}

connect = async () => {
    const provider = await detectEthereumProvider();
    if(provider) {
      const accounts = await provider.request({method: 'eth_requestAccounts'});
      this.setState({accounts: accounts})
    }
}

mint = async (_amount) => {
  if(this.state.contract !== null) {
    try {
      var price = _amount * 50000000000000000;
      await this.state.contract.methods.mintBinary(_amount).send({value: price, from: this.state.accounts[0]});
    } catch (e) {
      console.log('Error: ', e)
    }
  }
}

  render() {
    return (
      <div className="App">
      <div className='tb'>
        <button className='glow-on-hover' type='submit' onClick={(e) => this.connect(e)}>Connect</button>
      </div>
      <div className='title'>
        <h1 className='welcomeTitle'>BINARY NFT</h1>
      </div>
      <div>
        <div className='desc'>
          <p className='descp'>Binary NFTs is a collection of 518 programmatically created works of art inspired by the beauty of cryptography and mathematics in general.</p>
          <br/>
          <p className='descp'>Each NFT is created with a unique token ID that will be hashed to obtain the color palette and parameters for trait generation.</p>
          <br/>
          <p className='descp'>In addition to the physical traits, NFTs in this collection will gain rarity from their token ID, with certain IDs such as prime numbers, monodigit numbers, and others that will be revealed later being rarer.</p>
          <br/>
        </div>
      </div>
      <br></br>
      <br></br>
      <br></br>
      <div className='burnPrestige'>
      <div className='container'>
        <div className='cardx'>
          <h1 className='rmt'>BURN</h1>
          <p className='rmd'>Burn two pieces of the same gen to receive one piece of the next gen with random traits.</p>
          <br></br>
          <p className='rmd'>To burn, send one of the two NFTs to <a href='https://etherscan.io/address/0xd70f5a99Bb6dd12C3D1810CC7318D1380B5F7935' target='blank'>here</a>, and your new piece will take the place of the token with the lowest ID in your wallet within 24 hours.</p>
          <br></br>
          {/*<button className='featureButton' type='submit' onClick={(e) => this.burn(e)}>Burn</button>*/}
        </div>
      </div>
      <div className='container'>
        <div className='cardx'>
          <h1 className='rmt'>PRESTIGE</h1>
          <p className='rmd'>Burn two pieces of the same gen to receive one piece of the next gen with similar traits to the pieces combined.</p>
          <br></br>
          <p className='rmd'>To prestige, send one of the two NFTs to <a href='https://etherscan.io/address/0xec2639c9517F05D899987B6ea51359fF8656C649' target='blank'>here</a>, and your new piece will take the place of the token with the lowest ID in your wallet within 24 hours.</p>
          <br></br>
        </div>
      </div>
      </div>
      <br/>
      <br/>
      <div className='roadmapTitleContainer'>
        <h1 className='roadmapTitle'>FEATURES</h1>
      </div>
      <div className='roadmapElements'>
      <div className='container'>
        <div className='cardx'>
          <h1 className='rmt'>EVOLVE</h1>
          <p className='rmd'>Binary is an evolving collection. Pieces were minted at gen0. There are three ways that pieces can evolve: having the evolve trait, burning, or prestiging. The first evolution happened on 09/12/21. The second will take place 09/26/21, the third on 10/03/21, and the final on 10/10/21. After each evolution, the number of pieces that maintain the evolve trait after each evolutuon will decline. </p>
          <br/>
        </div>
      </div>
      <div className='container'>
        <div className='cardx'>
          <h1 className='rmt'>BURN</h1>
          <p className='rmd'>The burn mechanism will go live on 09/19/21. It will allow holders to obtain higher gen pieces with random traits by burning lower gen pieces in a 2:1 ratio. For example, two gen0 pieces would yield one gen1 piece. Burn can only be used to get up to the current max gen available through the evolve trait.</p>
        </div>
      </div>
      <div className='container'>
        <div className='cardx'>
          <h1 className='rmt'>PRESTIGE</h1>
          <p className='rmd'>The prestige mechanism will go live on 09/19/21. It will allow holders to obtain higher gen pieces by combining lower gen pieces in a 2:1 ratio. This mechanism will maintain similar traits to the two pieces being combined rather than yielding random traits such as through the burn mechanism. Evolve can only be used to get up to the current max gen available through the evolve trait.</p>
          <br/>
        </div>
      </div>
      </div>
      <div className='aboutTitleContainer'>
        <h1 className='aboutTitle'>ABOUT</h1>
      </div>
      <div className='about'>
      <p className='descp'>I created Binary as an individual and completed all the core features while working alone. However, after hearing from the community I am planning to bring in a few others to help with certain aspects of the project such as marketing and community management. If you have a desire to help with either of these aspects, or in any other way then please reach out via DM on twitter or discord.</p>
      </div>
      <div>
      <footer>
        <SocialIcon className='sic' url="https://twitter.com/BinaryNfts"/>
        <SocialIcon className='sic' url="https://discord.gg/Hgy6RqEkjG"/>
      </footer>
      </div>
    </div>
    );
  }
}

export default App;
